<template>
  <div class="view-home">
    <div class="one-training-title-block">
      <div class="container" style="position: relative; z-index: 10">
        <nav class="breadcrumbs-block">
          <ul class="breadcrumbs-list">
            <li class="breadcrumbs-item"><a href="/new-main">Главная</a></li>
            <li class="breadcrumbs-item"> Мероприятия</li>
            <li class="breadcrumbs-item"> Международные курсы повышения квалификации</li>
            <li class="breadcrumbs-item"> Программа повышения квалификации Южного федерального университета «Технологии профессиональной ориентации школьников»</li>
          </ul>
        </nav>
        <div class="one-forum-title">
          Международные курсы повышения квалификации
        </div>
        <div class="one-forum-subtitle">
          <router-link to="#">
            <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M32.5 57.375C45.9619 57.375 56.875 46.4619 56.875 33C56.875 19.5381 45.9619 8.625 32.5 8.625C19.0381 8.625 8.125 19.5381 8.125 33C8.125 46.4619 19.0381 57.375 32.5 57.375Z"
                  stroke="white" stroke-width="3" stroke-miterlimit="10"/>
              <path d="M36.5625 23.8594L26.4062 33L36.5625 42.1406" stroke="white" stroke-width="3"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </router-link>
          Программа повышения квалификации Южного федерального университета «Технологии профессиональной ориентации школьников»
        </div>
      </div>
    </div>

    <div class="one-training-back">
      <el-image
          style="width: 100%; height: 100%"
          src="/images/forums-slide-img-2.png"
          fit="fit"></el-image>
    </div>

    <div class="back-img-bubble">
      <div class="container">
        <div class="one-forum-details-block">
          <div class="one-forum-details-left">
            <div class="line-top"></div>
            <div class="one-seminar-section-title">
              Общее описание
            </div>
            <div class="one-forum-section-text">
              Курсы повышения квалификации предназначены для педагогов, психологов и специалистов, работающих с
              подростками, и направлены на изучение современных технологий и методик профессиональной ориентации
              школьников.
            </div>
            <div class="one-forum-section-text">
              Участники получат практические навыки по оценке интересов, способностей и личностных особенностей
              учащихся,
              а также научатся разрабатывать индивидуализированные программы профессиональной ориентации.
            </div>
            <div class="line-top"></div>
            <div class="one-seminar-section-title">
              Задачи курса
            </div>
            <div class="one-forum-section-text">
              1. Познакомить с основными аспектами профессиональной ориентации подростков.<br>
              2. Освоить методы диагностики интересов, способностей и личностных характеристик школьников.<br>
              3. Изучить современные подходы к профессиональному консультированию школьников.<br>
              4. Разработать практические программы и инструменты для профессиональной ориентации в школьной среде.<br>
            </div>
          </div>
          <div class="one-training-details-right">
            <div class="one-forum-results">
              <div class="one-forum-results-title">
                Длительность курса
              </div>
              <div class="one-forum-results-text">
                Курс проводится в течение нескольких недель с недельными занятиями. В рамках каждого модуля
                предусмотрены
                лекции, практические занятия, самостоятельная работа участников и домашние задания.<br>
                После успешного завершения курса участники получают сертификат о повышении квалификации.
              </div>
              <div class="one-forum-results-details">
                <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="61" height="61" rx="13.9377" fill="#F1E8FF"/>
                  <path
                      d="M36.7814 16.3684V22.6491M24.2201 16.3684V22.6491M17.9395 28.9297H43.062M17.9395 22.6491C17.9395 21.8162 18.2703 21.0174 18.8592 20.4285C19.4482 19.8396 20.2469 19.5087 21.0798 19.5087H39.9217C40.7546 19.5087 41.5533 19.8396 42.1423 20.4285C42.7312 21.0174 43.062 21.8162 43.062 22.6491V41.491C43.062 42.3239 42.7312 43.1226 42.1423 43.7115C41.5533 44.3005 40.7546 44.6313 39.9217 44.6313H21.0798C20.2469 44.6313 19.4482 44.3005 18.8592 43.7115C18.2703 43.1226 17.9395 42.3239 17.9395 41.491V22.6491ZM24.2201 35.2104H27.3604V38.3507H24.2201V35.2104Z"
                      stroke="#6E00FA" stroke-width="2.28893" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Даты проведения: 05.07.2024 - 07.07.2024 гг
              </div>
              <div class="one-forum-results-details">
                <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="61" height="61" rx="13.833" fill="#F1E8FF"/>
                  <path
                      d="M25.5785 28.8593C25.5785 30.1647 26.0971 31.4167 27.0202 32.3397C27.9432 33.2628 29.1952 33.7814 30.5006 33.7814C31.806 33.7814 33.058 33.2628 33.9811 32.3397C34.9041 31.4167 35.4227 30.1647 35.4227 28.8593C35.4227 27.5539 34.9041 26.3019 33.9811 25.3788C33.058 24.4558 31.806 23.9372 30.5006 23.9372C29.1952 23.9372 27.9432 24.4558 27.0202 25.3788C26.0971 26.3019 25.5785 27.5539 25.5785 28.8593Z"
                      stroke="#6E00FA" stroke-width="2.27174" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M39.7821 38.1407L32.8206 45.1022C32.2053 45.7169 31.3711 46.0621 30.5014 46.0621C29.6317 46.0621 28.7976 45.7169 28.1823 45.1022L21.2192 38.1407C19.3836 36.305 18.1336 33.9663 17.6272 31.4202C17.1208 28.8741 17.3807 26.235 18.3742 23.8367C19.3677 21.4383 21.05 19.3884 23.2085 17.9462C25.367 16.5039 27.9046 15.7342 30.5006 15.7342C33.0966 15.7342 35.6343 16.5039 37.7927 17.9462C39.9512 19.3884 41.6336 21.4383 42.627 23.8367C43.6205 26.235 43.8805 28.8741 43.3741 31.4202C42.8677 33.9663 41.6176 36.305 39.7821 38.1407Z"
                      stroke="#6E00FA" stroke-width="2.27174" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                Место проведения: г. Астана, пр. Мангилик Ел, 55/20
              </div>
              <div class="one-training-certificate">
                <el-image
                    style="width: 267px; height: 100%"
                    src="/images/certificate-training.png"
                    fit="fit"></el-image>
              </div>
            </div>
          </div>
        </div>

        <div class="one-forum-speakers-block">

          <div class="line-top"></div>
          <div class="one-seminar-section-title">
            Преподаватели
          </div>
          <div class="one-training-speakers-slider">
            <carousel :perPage="3" :loop="true" :autoplay="false" :scroll-per-page="false" :autoplayTimeout="5000"
                      :navigationEnabled="true"
                      :paginationEnabled="false">
              <slide class="one-forum-slide-block">
                <div class="one-forum-slide">
                  <div class="one-forum-slide-details">
                    <img src="/images/slide-one-forum.png" width="80" height="80" alt=""/>
                    <div>
                      <div class="one-forum-slide-name">
                        Нурлан Абдрахманов
                      </div>
                      <div class="one-forum-slide-job">
                        Доктор
                      </div>
                    </div>
                  </div>
                  <div class="one-forum-slide-text">
                    - Кандидат психологических наук, эксперт по профессиональной ориентации школьников.<br>
                    - Разработчик индивидуальных программ профориентации для молодежи.<br>
                    - Опыт проведения психологических тренингов и семинаров для школьных педагогов в Казахстане.<br>
                  </div>
                </div>
              </slide>
              <slide class="one-forum-slide-block">
                <div class="one-forum-slide">
                  <div class="one-forum-slide-details">
                    <img src="/images/slide-one-forum.png" width="80" height="80" alt=""/>
                    <div>
                      <div class="one-forum-slide-name">
                        Нурлан Абдрахманов
                      </div>
                      <div class="one-forum-slide-job">
                        Доктор
                      </div>
                    </div>
                  </div>
                  <div class="one-forum-slide-text">
                    - Кандидат психологических наук, эксперт по профессиональной ориентации школьников.<br>
                    - Разработчик индивидуальных программ профориентации для молодежи.<br>
                    - Опыт проведения психологических тренингов и семинаров для школьных педагогов в Казахстане.<br>
                  </div>
                </div>
              </slide>
              <slide class="one-forum-slide-block">
                <div class="one-forum-slide">
                  <div class="one-forum-slide-details">
                    <img src="/images/slide-one-forum.png" width="80" height="80" alt=""/>
                    <div>
                      <div class="one-forum-slide-name">
                        Нурлан Абдрахманов
                      </div>
                      <div class="one-forum-slide-job">
                        Доктор
                      </div>
                    </div>
                  </div>
                  <div class="one-forum-slide-text">
                    - Кандидат психологических наук, эксперт по профессиональной ориентации школьников.<br>
                    - Разработчик индивидуальных программ профориентации для молодежи.<br>
                    - Опыт проведения психологических тренингов и семинаров для школьных педагогов в Казахстане.<br>
                  </div>
                </div>
              </slide>
              <slide class="one-forum-slide-block">
                <div class="one-forum-slide">
                  <div class="one-forum-slide-details">
                    <img src="/images/slide-one-forum.png" width="80" height="80" alt=""/>
                    <div>
                      <div class="one-forum-slide-name">
                        Нурлан Абдрахманов
                      </div>
                      <div class="one-forum-slide-job">
                        Доктор
                      </div>
                    </div>
                  </div>
                  <div class="one-forum-slide-text">
                    - Кандидат психологических наук, эксперт по профессиональной ориентации школьников.<br>
                    - Разработчик индивидуальных программ профориентации для молодежи.<br>
                    - Опыт проведения психологических тренингов и семинаров для школьных педагогов в Казахстане.<br>
                  </div>
                </div>
              </slide>
              <slide class="one-forum-slide-block">
                <div class="one-forum-slide">
                  <div class="one-forum-slide-details">
                    <img src="/images/slide-one-forum.png" width="80" height="80" alt=""/>
                    <div>
                      <div class="one-forum-slide-name">
                        Нурлан Абдрахманов
                      </div>
                      <div class="one-forum-slide-job">
                        Доктор
                      </div>
                    </div>
                  </div>
                  <div class="one-forum-slide-text">
                    - Кандидат психологических наук, эксперт по профессиональной ориентации школьников.<br>
                    - Разработчик индивидуальных программ профориентации для молодежи.<br>
                    - Опыт проведения психологических тренингов и семинаров для школьных педагогов в Казахстане.<br>
                  </div>
                </div>
              </slide>

            </carousel>
          </div>
        </div>

        <div class="one-trainings-program-block">
          <div class="line-top"></div>
          <div class="one-seminar-section-title">
            Программа Курсов
          </div>

          <a class="one-forum-program-link">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.0234 17.6187C10.8148 17.6187 10.6062 17.5391 10.4492 17.382L6.79766 13.7281C6.48359 13.4117 6.48359 12.8984 6.8 12.582C7.11406 12.2656 7.62734 12.2656 7.94375 12.582L10.2148 14.8531V1.31094C10.2148 0.863281 10.5781 0.5 11.0258 0.5C11.4734 0.5 11.8367 0.863281 11.8367 1.31094V14.8531L14.1078 12.582C14.2602 12.4297 14.4641 12.3453 14.6797 12.3453C14.8953 12.3453 15.0992 12.4297 15.2516 12.582C15.568 12.8984 15.568 13.4117 15.2516 13.7281L11.5977 17.382C11.4383 17.5391 11.232 17.6187 11.0234 17.6187ZM19.8781 14.8203C19.8781 14.3727 20.2414 14.0094 20.6891 14.0094C21.1367 14.0094 21.5 14.3727 21.5 14.818V20.0422C21.5 20.8438 20.8484 21.4977 20.0445 21.4977H1.95547C1.15391 21.4977 0.5 20.8461 0.5 20.0422V14.818C0.5 14.3727 0.860938 14.007 1.30859 14.007C1.75625 14.007 2.11953 14.3703 2.11953 14.818V19.4C2.11953 19.6672 2.3375 19.8828 2.60234 19.8828H19.3953C19.6625 19.8828 19.8781 19.6648 19.8781 19.4V14.8203Z"
                    fill="#1090CB"/>
            </svg>
            Программа Курсов.pdf*
          </a>
          <div class="one-trainings-modules">
            <div class="one-trainings-module">
              <div class="one-trainings-module-text">
                <span>Модуль 1:</span> Основы профессиональной ориентации<br><br>
                - Введение в профессиональную ориентацию школьников.<br>
                - Теоретические основы диагностики интересов и способностей учащихся.<br>
                - Методы оценки личностных характеристик и профессиональных предпочтений подростков.
              </div>
            </div>
            <div class="one-trainings-module">
              <div class="one-trainings-module-text">
                <span>Модуль 2:</span> Основы профессиональной ориентации<br><br>
                - Введение в профессиональную ориентацию школьников.<br>
                - Теоретические основы диагностики интересов и способностей учащихся.<br>
                - Методы оценки личностных характеристик и профессиональных предпочтений подростков.
              </div>
            </div>
            <div class="one-trainings-module">
              <div class="one-trainings-module-text">
                <span>Модуль 3:</span> Основы профессиональной ориентации<br><br>
                - Введение в профессиональную ориентацию школьников.<br>
                - Теоретические основы диагностики интересов и способностей учащихся.<br>
                - Методы оценки личностных характеристик и профессиональных предпочтений подростков.
              </div>
            </div>
          </div>
          <div class="d-flex">
            <a class="one-seminar-program-link">
              <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.0273 22.1328C9.69141 26.1055 4.39453 26.1055 4.39453 26.1055C4.39453 26.1055 4.39453 20.8086 8.36719 19.4727"
                    stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M22.957 12.8517L15 20.8087L9.69141 15.5001L17.6484 7.5431C20.6602 4.53139 23.6719 4.56654 24.9609 4.75404C25.1598 4.78058 25.3445 4.87184 25.4864 5.01374C25.6283 5.15564 25.7196 5.34029 25.7461 5.5392C25.9336 6.82826 25.9687 9.83998 22.957 12.8517Z"
                    stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M21.6328 14.1758V21.7461C21.6293 21.9934 21.5282 22.2293 21.3516 22.4023L17.5664 26.1992C17.4463 26.3192 17.2959 26.4043 17.1313 26.4454C16.9666 26.4866 16.7938 26.4823 16.6314 26.433C16.469 26.3836 16.3231 26.2912 16.2091 26.1654C16.0951 26.0396 16.0175 25.8852 15.9844 25.7188L15 20.8086"
                    stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M16.323 8.86719H8.75272C8.50544 8.87071 8.26956 8.9718 8.09647 9.14844L4.2996 12.9336C4.17965 13.0537 4.09455 13.2041 4.05338 13.3687C4.01222 13.5334 4.01652 13.7062 4.06585 13.8686C4.11517 14.031 4.20766 14.1769 4.33344 14.2909C4.45923 14.4049 4.61359 14.4825 4.78007 14.5156L9.69022 15.5"
                    stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Зарегистрироваться на курсы
            </a>

            <a class="one-trainings-link-info">
              Запросить более подробную информацию
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  mounted() {

  },
  filters: {

  },
  data() {
    return {

    }
  }
}
</script>
<style>

.one-forum-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  color: #FFF;
}

.one-forum-subtitle {
  font-size: 30px;
  font-weight: 800;
  line-height: 45px;
  color: #FFF;
  display: flex;
  gap: 25px;
  align-items: center;
}

.one-forum-details-block {
  display: flex;
  justify-content: space-between;
}

.one-forum-details-left {
  width: 49%;
}

.one-training-details-right {
  width: 49%;
  display: flex;
  flex-direction: column;
}

.one-forum-results {
  box-shadow: 0px 2.74px 105.6px 0px #0000000A;
  padding: 40px 0 40px 40px;
  position: relative;
}

.one-forum-results::before {
  position: absolute;
  content: "";
  width: 7px;
  height: 138px;
  background-color: #0CACDE;
  top: 50px;
  left: 40px;
}

.one-forum-results::after {
  position: absolute;
  content: "";
  width: 100vw;
  height: 100%;
  background-color: #FFF;
  top: 0px;
  left: 538px;
}

.one-seminar-section-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 61.2px;
  color: #32436D;
  margin-bottom: 25px;
}

.one-forum-section-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  color: #545454;
  margin-bottom: 25px;
}

.one-forum-details-block .line-top {
  margin-top: 40px;
  margin-bottom: 25px;
}

.one-forum-results-title {
  padding-left: 32px;
  font-size: 36px;
  font-weight: 600;
  line-height: 61.2px;
  color: #32436D;
}

.one-forum-results-text {
  padding-left: 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  color: #545454;
  margin-bottom: 40px;
}

.one-forum-results-details {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #2E0D64;

  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.one-forum-speakers-block .line-top {
  margin-bottom: 21px;
}

.one-training-speakers-slider {

}

.one-forum-slide-details {
  display: flex;
  gap: 25px;
  align-items: center;
}

.one-forum-slide-details img {
  border-radius: 50%;
}

.one-forum-slide-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #32436D;
}

.one-forum-slide-job {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #9497A1;
}

.one-forum-slide-text {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #9497A1;
}

.one-forum-slide {
  padding: 35px;
  box-shadow: 0px 4px 12px 0px rgba(12, 68, 204, 0.1);
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.one-forum-slide-block {
  padding: 5px;
  padding-right: 10px;
}

.one-trainings-program-block .line-top {
  margin-top: 30px;
  margin-bottom: 25px;
}

.one-trainings-program-block .one-seminar-section-title {
  margin-bottom: 25px;
}

.one-forum-program-link {
  border: 1.33px dashed #1090CB;

  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: #1090CB;
  padding: 16px 51px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
}

.one-forum-program-link:hover {
  text-decoration: none;
  color: #1090CB;
}

.one-forum-program-day {
  max-width: 700px;
  padding: 33px 15px;
  box-shadow: 0px 2.74px 105.6px 0px #0000000A;

}

.day-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #1090CB;
  margin-bottom: 40px;
}

.day-section-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #545454;
  margin-bottom: 25px;
}

.accent-color {
  color: #1090CB;
}

.one-seminar-program-days {
  position: relative;
  height: 600px;
  margin: 50px 0;
}

.one-forum-program-day {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #FFF;
}

.one-forum-program-day:hover {
  z-index: 10;
}

.day-1 {
  z-index: 3;
  transform: translate(0px, 0px);
}

.day-2 {
  z-index: 2;
  transform: translate(200px, 0px);
}

.day-3 {
  z-index: 1;
  transform: translate(400px, 0px);
}

.one-seminar-program-link {
  background-color: #FA8443;
  padding: 12px 40px;
  border-radius: 14px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32.69px;
  color: #FFF;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.one-seminar-program-link:hover {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

</style>